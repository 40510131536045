import * as React from "react";
import { dataLayerPush } from "../classes/gtm";

const GTMPage = () => {
	const push = () => {
		dataLayerPush({
			userId: "123",
		});
	};
	return (
		<div>
			<button onClick={push}>Push</button>
		</div>
	);
};

export default GTMPage;
